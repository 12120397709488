export const USER_PROPERTIES = {
  accessTokenSigningKey: 'accessTokenSigningKey',
  gdprProfileP13nConsentGranted: 'GDPRProfileP13nConsentGranted',
  authValidationToken: 'authValidationToken',
  defaultCarrier: 'defaultCarrier',
  lockedAt: 'lockedAt',
  passwordResetTokenSigningKey: 'passwordResetTokenSigningKey',
  securityStatus: 'securityStatus',
  autoLogin: 'autoLogin',
  firstPurchaseDate: 'firstPurchaseDate',
  password: 'password',
  profileType: 'profileType',
  authenticationSecretKey: 'authenticationSecretKey',
  lifetimeAOV: 'lifetimeAOV',
  active: 'active',
  taxExemptionCode: 'taxExemptionCode',
  abandonedOrderCount: 'abandonedOrderCount',
  derivedApprovalRequired: 'derivedApprovalRequired',
  lifetimeCurrencyCode: 'lifetimeCurrencyCode',
  lastName: 'lastName',
  gender: 'gender',
  generatedPassword: 'generatedPassword',
  numberOfOrders: 'numberOfOrders',
  login: 'login',
  member: 'member',
  email: 'email',
  numberOfVisits: 'numberOfVisits',
  realmId: 'realmId',
  lastEmailed: 'lastEmailed',
  firstVisitDate: 'firstVisitDate',
  lastActivity: 'lastActivity',
  lastPurchaseDate: 'lastPurchaseDate',
  gdprProfileP13nConsentDate: 'GDPRProfileP13nConsentDate',
  allowPartialShipment: 'allowPartialShipment',
  lastPurchaseAmount: 'lastPurchaseAmount',
  registrationDate: 'registrationDate',
  lastPasswordGenerate: 'lastPasswordGenerate',
  daytimeTelephoneNumber: 'daytimeTelephoneNumber',
  customerContactId: 'customerContactId',
  taxExempt: 'taxExempt',
  lastVisitDate: 'lastVisitDate',
  previousVisitDate: 'previousVisitDate',
  firstName: 'firstName',
  emailStatus: 'emailStatus',
  derivedTaxExemptionCode: 'derivedTaxExemptionCode',
  expressCheckout: 'expressCheckout',
  lastPasswordUpdate: 'lastPasswordUpdate',
  userType: 'userType',
  description: 'description',
  locale: 'locale',
  receiveEmailDate: 'receiveEmailDate',
  lifetimeSpend: 'lifetimeSpend',
  derivedOrderPriceLimit: 'derivedOrderPriceLimit',
  failedAttempts: 'failedAttempts',
  receiveEmail: 'receiveEmail',
  currentLocation: 'currentLocation',
  middleName: 'middleName'
};

export const USER_DYNAMIC_PROPERTIES = {
  secBanch: 'cips_SecBanch',
  myEventsTrainingList: 'cips_my_events_training_list',
  lmsMemberId: 'lms_memberId',
  cohorts: 'cips_Cohorts',
  jobTitle: 'cips_jobTitle',
  memberGrade: 'cips_MemberGrade',
  parnassusId: 'cips_parnassusId',
  examCentre: 'cips_ExamCentre',
  prefBranch: 'cips_PrefBranch',
  extendedProfile: 'cips_ExtendedProfile',
  memberGroup: 'cips_MemberGroup',
  professionalRegisterOptOut: 'cips_professionalRegisterOptOut',
  studyCentre: 'cips_StudyCentre',
  dietRequirementDetail: 'cips_dietRequirementDetail',
  isApprentice: 'cips_isApprentice',
  membershipSubStatus: 'cips_MembershipSubStatus',
  membershipNumber: 'cips_MembershipNumber',
  currentOrganization: 'cips_currentOrganization',
  membershipProductId: 'cips_membershipProductId',
  validEthicsTest: 'cips_validEthicsTest',
  centreTypeRelationship: 'cips_centreTypeRelationship',
  almsMemberId: 'alms_memberId',
  preferredTimeZone: 'cips_preferredTimeZone',
  accessRequirementDetail: 'cips_accessRequirementDetail',
  specialReq: 'cips_SpecialReq',
  branchRoles: 'cips_branchRoles',
  cuid: 'cips_CUID',
  gdprMail: 'cips_gdprMail',
  activeSubscriptions: 'cips_activeSubscriptions',
  currentStudyCentre: 'cips_currentStudyCentre',
  agreeTC: 'cips_agreeTC',
  accessRequirement: 'cips_accessRequirement',
  cashPaymentGatewayMode: 'cips_cashPaymentGatewayMode',
  gdprPhone: 'cips_gdprPhone',
  elligibleGraduate: 'cips_elligibleGraduate',
  membershipStatus: 'cips_MembershipStatus',
  membership: 'cips_Membership',
  companyName: 'cips_companyName',
  defaultCountryISO: 'cips_defaultCountryISO',
  profileImage: 'cips_profileImage',
  savedBookmarks: 'cips_savedBookmarks',
  isOnDemandStudent: 'cips_isOnDemandStudent',
  dob: 'cips_dob',
  careerGoals: 'cips_careerGoals',
  memberExpiryDate: 'cips_MemberExpiryDate',
  agreeCC: 'cips_agreeCC',
  areasOfInterest: 'cips_areasOfInterest',
  volunteering: 'cips_volunteering',
  supplementalData: 'cips_supplementalData',
  currentStudy: 'cips_CurrentStudy',
  dietRequirement: 'cips_dietRequirement',
  currentApplication: 'cips_CurrentApplication',
  allocatePurchase: 'cips_allocatePurchase',
  reportingCategory: 'cips_reportingCategory'
};
